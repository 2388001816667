/* Container Principale */
.library-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  color: #333;
}

/* Contenuto della Libreria */
.library-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px; /* Larghezza della Sidebar */
  transition: margin-left 0.3s ease;
}

.library-content.sidebar-closed {
  margin-left: 0;
}

@media (max-width: 768px) {
  .library-content {
    margin-left: 0;
  }
}

/* AppBar */
.app-bar {
  z-index: 1400; /* Superiore alla Sidebar */
  transition: all 0.3s ease;
  background-color: #3f51b5; /* Stesso colore della Sidebar */
}

/* Titolo della Libreria */
.app-title {
  flex-grow: 0;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Georgia', serif; /* Font più elegante */
}

/* Contenitore per la Data */
.current-date {
  text-align: center;
  color: #fff;
  transition: color 0.3s ease;
  font-family: 'Georgia', serif; /* Font più elegante */
}

.current-date:hover {
  color: #ffeb3b;
}

/* Container Content */
.library-container-content {
  padding: 80px 24px 24px 24px; /* Aggiunto padding superiore per compensare l'AppBar */
}

/* Card Styling */
.library-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 16px; /* Bordo più arrotondato */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Sfondo bianco per eleganza */
}

.library-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px; /* Padding più consistente */
}

.card-media {
  height: 160px; /* Altezza aumentata */
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.card-title {
  margin-top: 16px;
  font-weight: 700;
  font-family: 'Georgia', serif; /* Font più elegante */
  color: #3f51b5; /* Colore coerente */
}

.card-description {
  margin-top: 12px;
  color: #555;
  font-family: 'Arial', sans-serif;
}

/* Card Actions */
.card-actions {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.open-button {
  border: 2px solid #3f51b5;
  color: #3f51b5;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.open-button:hover {
  background-color: #3f51b5;
  color: #fff;
}

/* Footer */
.library-footer {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.05);
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto; /* Spinge il footer verso il basso */
}

.library-footer .social-buttons {
  display: flex;
  justify-content: center;
  gap: 24px; /* Spazio maggiore tra le icone */
  margin-bottom: 12px;
}

.library-footer .social-button {
  font-size: 2rem;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.library-footer .social-button:hover {
  color: #3f51b5;
  transform: scale(1.2);
}

/* Footer Text */
.footer-text {
  font-size: 1rem;
  color: #777;
  font-family: 'Arial', sans-serif;
}

/* Responsività */
@media (max-width: 1024px) {
  .app-title {
    font-size: 1.6rem;
  }

  .current-date {
    font-size: 1rem;
  }

  .library-container-content {
    padding: 60px 16px 16px 16px;
  }
}

@media (max-width: 768px) {
  .library-container-content {
    padding: 60px 16px 16px 16px;
  }

  .library-footer .social-button {
    font-size: 1.8rem;
  }

  .footer-text {
    font-size: 0.9rem;
  }

  .app-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .library-container-content {
    padding: 60px 8px 8px 8px;
  }

  .app-title {
    font-size: 1.3rem;
  }

  .current-date {
    font-size: 0.9rem;
  }

  .library-footer .social-button {
    font-size: 1.6rem;
  }

  .footer-text {
    font-size: 0.8rem;
  }
}
