/* src/styles/Dashboard.css */

/* Container Principale */
.dashboard-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: 'Helvetica', 'Arial', sans-serif;
  color: #333;
}

/* Contenuto della Dashboard */
.dashboard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px; /* Larghezza della Sidebar */
  transition: margin-left 0.3s ease;
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 0;
  }
}

/* AppBar */
.app-bar {
  z-index: 1400; /* Superiore alla Sidebar */
  transition: all 0.3s ease;
  background-color: #3f51b5; /* Stesso colore della Sidebar */
}

/* Titolo della Dashboard */
.app-title {
  flex-grow: 0;
  text-align: left; /* Allinea il titolo a sinistra */
  cursor: pointer;
}

/* Contenitore per la Data */
.current-date {
  text-align: right;
  color: white;
  transition: color 0.3s ease;
}

.current-date:hover {
  color: #ffeb3b;
}

/* Container Content */
.dashboard-container-content {
  padding: 24px;
}

/* Paper Styling */
.paper {
  padding: 16px;
  border-radius: 8px;
}

/* Titoli delle Sezioni */
.section-title {
  margin-bottom: 16px;
  font-weight: bold;
}

/* Input Box */
.input-box {
  margin-bottom: 16px;
}

/* Azioni */
.actions-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.add-button,
.generate-pdf-button {
  min-width: 150px;
}

/* Tabella */
.table-header {
  background-color: #3f51b5;
}

.header-cell {
  color: white;
  font-weight: bold;
}

/* Azioni nella Tabella */
.actions-cell {
  display: flex;
  align-items: center;
}

.action-button {
  margin-right: 8px;
}

/* Footer */
.dashboard-footer {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto; /* Spinge il footer verso il basso */
}

.dashboard-footer .social-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.dashboard-footer .social-button {
  font-size: 1.8rem;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.dashboard-footer .social-button:hover {
  color: #3f51b5;
  transform: scale(1.2);
}

/* Footer Text */
.footer-text {
  font-size: 0.9rem;
  color: #777;
}

/* Responsività */
@media (max-width: 1024px) {
  .app-title {
    font-size: 1.5rem;
  }

  .current-date {
    font-size: 0.9rem;
  }
}

/* Menu a Tendina per i Programmi Salvati */
.MuiFormControl-root {
  margin-left: 20px;
}

/* Pulsante "Salva Programma" */
button {
  text-transform: none;
}

@media (max-width: 768px) {
  .dashboard-container-content {
    padding: 16px;
  }

  .dashboard-footer .social-button {
    font-size: 1.5rem;
  }

  .footer-text {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .dashboard-container-content {
    padding: 8px;
  }

  .app-title {
    font-size: 1.2rem;
  }

  .current-date {
    font-size: 0.8rem;
  }

  .dashboard-footer .social-button {
    font-size: 1.5rem;
  }

  .footer-text {
    font-size: 0.7rem;
  }
}
