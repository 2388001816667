/* src/styles/CalendarPage.css */

/* Container Principale */
.calendar-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: 'Helvetica', 'Arial', sans-serif;
  color: #333;
}

/* Contenuto della CalendarPage */
.calendar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px; /* Larghezza della Sidebar */
  transition: margin-left 0.3s ease;
}

.sidebar.closed + .calendar-content {
  margin-left: 0;
}

@media (max-width: 768px) {
  .calendar-content {
    margin-left: 0;
  }
}

/* AppBar */
.app-bar {
  z-index: 1400; /* Superiore alla Sidebar */
  transition: all 0.3s ease; /* Aggiungi transizioni fluide */
  background-color: #3f51b5; /* Stesso colore della Sidebar */
}

/* Titolo della CalendarPage */
.app-title {
  cursor: pointer;
  color: white;
  margin-left: 16px;
  flex-grow: 1;
  transition: color 0.3s ease;
}

.app-title:hover {
  color: #ffeb3b; /* Cambia colore al passaggio del mouse */
}

/* Contenitore per la Data */
.current-date-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Data Odierna */
.current-date {
  color: white;
  transition: color 0.3s ease;
}

.current-date:hover {
  color: #ffeb3b;
}

/* Sezione Paper */
.paper {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Titoli delle Sezioni */
.section-title {
  margin-bottom: 15px;
  color: #2c3e50;
}

/* Modal Box */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 3px 7px rgba(0,0,0,0.3);
  max-width: 600px;
  width: 90%;
}

/* Footer */
.calendar-footer {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto; /* Spinge il footer verso il basso */
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.social-button {
  font-size: 1.8rem;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.social-button:hover {
  color: #3f51b5;
  transform: scale(1.2);
}

/* Footer Text */
.footer-text {
  font-size: 0.9rem;
  color: #777;
}

/* Responsività */
@media (max-width: 1024px) {
  .app-title {
    font-size: 1.5rem;
  }

  .current-date {
    font-size: 0.9rem;
  }

  .section-title {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .app-title {
    font-size: 1.5rem;
  }

  .current-date-container {
    justify-content: center;
    margin-top: 10px;
  }

  .current-date {
    font-size: 0.9rem;
  }

  .modal-box {
    padding: 16px;
  }
}

@media (max-width: 480px) {
  .app-title {
    font-size: 1.2rem;
  }

  .current-date {
    font-size: 0.8rem;
  }

  .modal-box {
    padding: 16px;
  }

  .social-button {
    font-size: 1.5rem;
  }

  .footer-text {
    font-size: 0.8rem;
  }
}
