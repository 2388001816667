/* src/styles/Welcome.css */

.welcome-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

/* Header */
.welcome-header {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-logo {
  max-width: 110px; /* Dimensione massima su schermi grandi */
  width: 20vw;       /* Dimensione relativa */
  height: auto;
  transition: max-width 0.3s ease, width 0.3s ease; /* Transizioni fluide */
}

/* Main Content */
.welcome-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
}

.welcome-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
}

.welcome-date-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #eaf0f6;
  padding: 10px 20px;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-icon {
  font-size: 1.2rem;
  color: #3498db;
  margin-right: 10px;
}

.welcome-date {
  font-size: 1rem;
  color: #555;
  margin: 0;
}

.welcome-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #34495e;
}

/* Buttons */
.welcome-buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.welcome-button {
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-button {
  background-color: #3498db;
}

.login-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.signup-button {
  background-color: #2ecc71;
}

.signup-button:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.reset-button {
  background-color: #e67e22;
}

.reset-button:hover {
  background-color: #d35400;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Gospel Section */
.gospel-section {
  background-color: #fff;
  padding: 20px 15px; /* Ridotto padding */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  max-width: 500px; /* Ridotta larghezza massima */
  width: 90%; /* Mantenuta larghezza fluida */
  text-align: center;
  margin-top: 20px; /* Aggiunta margine superiore */
}

.gospel-title {
  font-size: 1.6rem; /* Ridotto font */
  margin-bottom: 8px;
  color: #2c3e50;
}

.gospel-passage {
  font-size: 1.1rem; /* Ridotto font */
  color: #34495e;
  font-style: italic;
}

/* Footer */
.welcome-footer {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.social-icon {
  font-size: 1.8rem;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #3498db;
  transform: scale(1.2);
}

.footer-text {
  font-size: 0.9rem;
  color: #777;
}

/* Responsività */
@media (max-width: 1024px) {
  .welcome-logo {
    max-width: 150px;
    width: 15vw;
  }
}

@media (max-width: 768px) {
  .welcome-logo {
    max-width: 120px;
    width: 20vw;
  }
  
  .welcome-title {
    font-size: 2rem;
  }

  .welcome-subtitle {
    font-size: 1rem;
  }

  .welcome-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .gospel-section {
    padding: 15px 10px; /* Ulteriore riduzione padding */
    max-width: 90%; /* Mantenere la larghezza fluida su dispositivi mobili */
  }

  .gospel-title {
    font-size: 1.4rem;
  }

  .gospel-passage {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .welcome-logo {
    max-width: 100px;
    width: 25vw;
  }
}
