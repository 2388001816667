/* src/styles/Details.css */

.details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo semi-trasparente */
}

.details-content {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  outline: none;
  margin: 40px auto;
}

.photo-container {
  text-align: center;
}

.details-avatar {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.details-avatar:hover,
.details-avatar:active {
  transform: scale(1.2);
}

.student-name {
  text-align: center;
  margin-top: 16px;
}

.student-detail {
  margin-top: 8px;
}

.button-container {
  text-align: center;
}

.close-button {
  margin-top: 16px;
}

/* Stile per il modal dell'immagine ingrandita */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Disposizione verticale */
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8); /* Sfondo semi-trasparente più scuro */
  outline: none;
  z-index: 1300; /* Assicura che il modal sia sopra gli altri elementi */
  padding: 20px; /* Aggiungi padding per evitare che l'immagine tocchi i bordi */
}

.enlarged-image {
  max-width: 90%;
  max-height: 80vh; /* Ridotto per fare spazio al pulsante */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.enlarged-image:hover {
  transform: scale(1.05);
}

/* Stile per il pulsante di chiusura nell'immagine ingrandita */
.close-image-button {
  margin-top: 16px;
  padding: 8px 16px;
}
