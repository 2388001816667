/* src/styles/LibraryDetail.css */

/* Container Principale */
.library-detail-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background: #f0f4f8;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #333;
  }
  
  /* Contenuto della Libreria Dettagli */
  .library-detail-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 250px; /* Larghezza della Sidebar */
    transition: margin-left 0.3s ease;
  }
  
  .sidebar.closed + .library-detail-content {
    margin-left: 0;
  }
  
  @media (max-width: 768px) {
    .library-detail-content {
      margin-left: 0;
    }
  }
  
  /* AppBar */
  .app-bar {
    z-index: 1400; /* Superiore alla Sidebar */
    transition: all 0.3s ease;
    background-color: #3f51b5; /* Stesso colore della Sidebar */
  }
  
  /* Titolo della Libreria */
  .app-title {
    flex-grow: 0;
    text-align: center;
    cursor: pointer;
  }
  
  /* Contenitore per la Data */
  .current-date {
    text-align: center;
    color: white;
    transition: color 0.3s ease;
  }
  
  .current-date:hover {
    color: #ffeb3b;
  }
  
  /* Container Content */
  .library-detail-container-content {
    padding: 24px;
  }
  
  /* Card Styling */
  .card {
    height: 100%;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .card-media {
    height: 140px;
  }
  
  .link-paper {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .link-paper a {
    text-decoration: none;
    color: #3f51b5;
  }
  
  .link-paper a:hover {
    text-decoration: underline;
  }
  
  /* Footer */
  .library-footer {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    bottom: 0;
    margin-top: auto; /* Spinge il footer verso il basso */
  }
  
  .library-footer .social-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  .library-footer .social-button {
    font-size: 1.8rem;
    color: #555;
    transition: color 0.3s, transform 0.3s;
  }
  
  .library-footer .social-button:hover {
    color: #3f51b5;
    transform: scale(1.2);
  }
  
  /* Footer Text */
  .footer-text {
    font-size: 0.9rem;
    color: #777;
  }
  
  /* Responsività */
  @media (max-width: 1024px) {
    .app-title {
      font-size: 1.5rem;
    }
  
    .current-date {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .library-detail-container-content {
      padding: 16px;
    }
  
    .library-footer .social-button {
      font-size: 1.5rem;
    }
  
    .footer-text {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .library-detail-container-content {
      padding: 8px;
    }
  
    .app-title {
      font-size: 1.2rem;
    }
  
    .current-date {
      font-size: 0.8rem;
    }
  
    .library-footer .social-button {
      font-size: 1.5rem;
    }
  
    .footer-text {
      font-size: 0.7rem;
    }
  }
  