/* src/styles/Auth.css */

/* Container Principale */
.auth-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f4f8;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

/* Header */
.auth-header {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-logo {
  max-width: 110px; /* Dimensione massima su schermi grandi */
  width: 20vw;       /* Dimensione relativa */
  height: auto;
  transition: max-width 0.3s ease, width 0.3s ease; /* Transizioni fluide */
}

/* Main Content */
.auth-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
}

.auth-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
}

.input {
  padding: 15px 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 50px;
  transition: border-color 0.3s;
}

.input:focus {
  border-color: #3498db;
  outline: none;
}

.button {
  padding: 15px;
  font-size: 1rem;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Links */
.auth-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.auth-link {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s;
}

.auth-link:hover {
  color: #2980b9;
}

/* Footer */
.auth-footer {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.social-button {
  font-size: 1.8rem;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.social-button:hover {
  color: #3498db;
  transform: scale(1.2);
}

.footer-text {
  font-size: 0.9rem;
  color: #777;
}

/* Responsività */
@media (max-width: 1024px) {
  .auth-logo {
    max-width: 150px;
    width: 15vw;
  }

  .auth-title {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .auth-logo {
    max-width: 120px;
    width: 20vw;
  }

  .auth-title {
    font-size: 1.8rem;
  }

  .auth-form {
    max-width: 90%;
  }

  .button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .auth-logo {
    max-width: 100px;
    width: 25vw;
  }

  .auth-title {
    font-size: 1.6rem;
  }

  .button {
    padding: 12px;
    font-size: 0.9rem;
  }
}
