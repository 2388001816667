/* src/styles/AdminDashboard.css */

.dashboard-container {
  display: flex;
  height: 100vh; /* Imposta l'altezza totale della finestra */
  overflow: hidden; /* Previene lo scorrimento globale */
}

.dashboard-content {
  flex-grow: 1;
  padding-top: 64px; /* Altezza dell'AppBar */
  overflow-y: auto; /* Abilita lo scorrimento verticale */
  height: calc(100vh - 64px); /* Calcola l'altezza rimanente */
}

.app-bar {
  z-index: 1400; /* Per essere sopra la Sidebar */
}

.menu-button {
  margin-right: 16px;
}

.app-title {
  flex-grow: 1;
}

.current-date-container {
  margin-left: auto;
}

.dashboard-container-content {
  padding: 24px;
}

.section-title {
  margin-bottom: 16px;
  font-weight: bold;
}

.paper {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
}

.user-card {
  padding: 16px;
  border-radius: 8px;
  text-align: center;
}

.catechist-avatar-container {
  display: flex;
  justify-content: center;
}

.catechist-avatar {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}

.catechist-name {
  margin-bottom: 4px;
}

.action-button {
  margin-right: 8px;
}

.export-button-container {
  margin-top: 16px;
  text-align: right;
}

.export-button {
  min-width: 200px;
}

.table-header {
  background-color: #f5f5f5;
}

.header-cell {
  font-weight: bold;
}

/* Correzione per la selezione multipla */
.MuiTableCell-root {
  padding: 8px;
}

.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-footer {
  /* Ripristina lo stile originale del footer */
  background-color: #f5f5f5; /* Colore originale */
  color: #333;
  padding: 20px;
  text-align: center;
}

.dashboard-footer .social-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.dashboard-footer .social-button {
  color: #333;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.dashboard-footer .social-button:hover {
  color: #ffeb3b; /* Colore al passaggio del mouse */
}

.dashboard-footer .footer-text {
  margin: 0;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-container-content {
    padding: 16px;
  }

  .export-button {
    min-width: 150px;
  }

  .catechist-avatar {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 480px) {
  .dashboard-container-content {
    padding: 8px;
  }

  .export-button {
    min-width: 150px;
  }

  .catechist-avatar {
    width: 50px;
    height: 50px;
  }

  .dashboard-footer {
    padding: 10px;
  }

  .dashboard-footer .social-button {
    font-size: 1rem;
  }

  .dashboard-footer .footer-text {
    font-size: 0.8rem;
  }
}

/* Correzione dell'Effetto Hover sull'Icona del Calendario */
.calendar-button {
  position: relative;
}

.calendar-button .MuiBadge-badge {
  transform: scale(1) translate(50%, -50%);
}

.calendar-button .MuiBadge-badge:last-of-type {
  transform: scale(1) translate(-50%, 50%);
}

/* Rimuovi eventuali trasformazioni o animazioni indesiderate */
.calendar-button:hover {
  /* Rimuovi trasformazioni che causano spostamenti */
  transform: none;
  transition: none;
}
