/* src/styles/Form.css */

/* Container della Form */
.form-container {
  display: flex;
  flex-direction: column;
}

/* Foto Upload */
.photo-upload {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

/* Anteprima Foto */
.photo-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ccc;
}

/* Azioni della Form */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

/* Modale per la Form */

/* Date Picker */
.date-picker {
  width: 100%;
}

/* Responsività per le Forms */
@media (max-width: 600px) {
  .photo-upload {
    flex-direction: column;
    align-items: flex-start;
  }

  .photo-preview {
    width: 80px;
    height: 80px;
  }
}
