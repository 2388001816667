/* src/styles/global.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif; /* Assicurati di aver importato il font */
    background-color: #f5f5f7; /* Colore di sfondo simile a apple.com */
    color: #1d1d1f; /* Colore del testo */
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  button {
    font-family: inherit;
    cursor: pointer;
  }
  
  input, textarea {
    font-family: inherit;
  }
  