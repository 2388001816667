/* src/styles/Sidebar.css */

/* Drawer Styling */
.custom-drawer .MuiDrawer-paper {
  width: 250px;
  background-color: #3f51b5; /* Colore desiderato, in linea con l'AppBar */
  color: white;
  top: 64px; /* Altezza dell'AppBar */
  height: calc(100% - 64px); /* Altezza rimanente */
  transition: width 0.3s ease;
}

/* Logo nella Sidebar */
.sidebar-logo {
  text-align: center;
  margin: 20px 0;
}

.sidebar-logo-image {
  max-width: 100px; /* Ridotto per evitare sovrapposizioni */
  width: 80%;
  height: auto;
  border-radius: 10px; /* Rimedio per evitare il bordo circolare se non necessario */
  border: 2px solid white;
  object-fit: contain;
}

/* Icone nella Sidebar */
.sidebar-icon {
  min-width: 40px;
  color: white;
}

/* Link annidati */
.nested-link {
  padding-left: 40px;
  font-size: 0.9rem;
}

/* Pulsante Logout */
.logout-button {
  margin-top: 20px;
}

/* Hover Effects */
.MuiListItem-root:hover {
  background-color: #303f9f !important;
}

/* Responsività */
@media (max-width: 768px) {
  .custom-drawer .MuiDrawer-paper {
    width: 200px;
  }

  .sidebar-logo-image {
    max-width: 80px;
  }
}

@media (max-width: 480px) {
  .custom-drawer .MuiDrawer-paper {
    width: 180px;
  }

  .sidebar-logo-image {
    max-width: 60px;
  }

  .nested-link {
    font-size: 0.8rem;
  }
}
